.errorMessage {
  z-index: 30; // Cover 🍪 prompt!
  padding: 2em;
  text-align: center;
  color: black;
  background: white;
  svg {
    margin: 1rem;
  }
  button {
    width: 100%;
  }
}
