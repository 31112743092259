@import './../../App.module';

$padding: 1.48rem;
$zIndex: 30;
$buttonBorder: 0.5px solid #cbcbcb;

.modal {
  z-index: $zIndex;
  position: fixed;
  top: calc(#{$headerHeight}/ 2 + 50%);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72.5%;
  border-radius: 0.85em;
  box-sizing: border-box;
  background: #ebebeb;
  color: black;
  text-align: center;

  &:focus {
    outline: none;
  }

  * {
    @include systemFont;
  }

  h1 {
    font-size: 1.15em;
    font-weight: 600;
  }

  .content {
    padding: $padding;

    & > * {
      margin: 0;
      padding: 0;
    }
    h1 {
      margin-bottom: 0.67 * $padding;
    }
    p {
      font-size: 0.95em;
    }
  }

  .actions {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    border-top: $buttonBorder;

    > button {
      width: 100%;
      border: none;
      padding: calc(#{$padding} / 2);
      background: none;
      font-size: 1.05em;
      color: #1b79f3;
      border-right: $buttonBorder;

      font-weight: normal;
      &:first-child {
        font-weight: 600;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

:global .ReactModal__Overlay {
  z-index: $zIndex;
  background-color: rgba(0, 0, 0, 0) !important;
  backdrop-filter: $blur;
  -webkit-backdrop-filter: $blur;
}
